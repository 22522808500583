<template>
  <div class="app-layout">
    <header class="header">
      <nav class="nav">
        <cgbank-logo class="navarea -logo" />
        <div
          v-if="(accountStore?.user?.email && isDisplay) || isDemoRoute"
          class="navarea -tag"
          @click="rotateIcon"
        >
          <div class="tag-content">
            タグで検索
          </div>
          <v-icon
            class="icon"
          >
            mdi-menu-down
          </v-icon>
        </div>
        <div
          v-if="shouldReturnFalseForLanding() && isDisplay"
          class="navarea -search-bar"
        >
          <artifact-search />
        </div>

        <div
          class="navarea -account"
        >
          <v-icon
            v-if="isDisplay"
            class="search-icon"
            :class="{ active: isRotated }"
            @click="rotateIcon"
          >
            mdi-tag-search
          </v-icon>
          <template
            v-if="(accountStore?.user?.email && isDisplay) || isDemoRoute"
          >
            <NuxtLink
              class="button -favorite"
              @click="handleLinkClick($event)"
            >
              お気に入り
            </NuxtLink>
            <NuxtLink
              class="button -like"
              @click="handleLinkClick($event)"
            >
              <v-icon>mdi-heart</v-icon>
            </NuxtLink>
          </template>
          <div
            v-if="accountStore?.user?.email"
            class="account-login"
          >
            <div v-if="accountStore?.user?.cgBankStatus === 'ENABLED'">
              <DropDownMenu
                :items="menuModal"
                :is-icon="true"
                icon="mdi-account-circle"
              />
            </div>
            <div
              v-else
              class="login-else"
            >
              <div
                class="button -logout"
                @click="logout"
              >
                <v-icon>
                  mdi-logout
                </v-icon>
              </div>
            </div>
          </div>
          <NuxtLink
            v-else
            class="button -login"
            to="/cgbank/artifacts"
            target="_blank"
          >
            ログイン
          </NuxtLink>
        </div>
      </nav>
    </header>

    <l-tag-search
      v-if="isRotated"
      v-on-click-outside="rotateIcon"
      @searched="rotateIcon"
    />
  </div>
</template>

<script setup lang="ts">
const { isDisplay } = defineProps({
  isDisplay: {
    type: Boolean,
    default: true,
  },
})
const accountStore = useAccountStore()
const user = computed(() => accountStore.user)
const portal = ref()
const route = useRoute()
const router = useRouter()
const isRotated = ref(false)
const isDemoRoute = computed(() => route.path === '/cgbank/demo')
const dialogStore = useDialogStore()
const isPlanButtonAllowed: boolean = isPlanButtonAllowedOnRoute(['/consent', '/verify', '/profile', '/plan', '/subscriptions/success'], route.path)
const menuModal = [{
  text: 'お支払い情報',
  action: () => handlePortalClick(),
}, {
  text: 'ログアウト',
  action: () => logout(),
}]

const toggleTagSearchOverflow = (isOpen: boolean): void => {
  if (isOpen) {
    document.querySelector('body')?.classList.add('body-scroll-hidden')
  } else {
    document.querySelector('body')?.classList.remove('body-scroll-hidden')
  }
}

const shouldReturnFalseForLanding = (): boolean => {
  if (route.fullPath === '/cgbank') {
    return false
  } else {
    return true
  }
}

const handleLinkClick = (event) => {
  if (isDemoRoute.value) {
    event.preventDefault()
    dialogStore.openDialog('sign-up-dialog')
  } else {
    router.push('/cgbank/favorites')
  }
}

const rotateIcon = () => {
  if (!isDemoRoute.value) {
    isRotated.value = !isRotated.value
    toggleTagSearchOverflow(isRotated.value)
    // searchStore.clearSelectedTags()
  } else {
    dialogStore.openDialog('sign-up-dialog')
  }
}

const rotateStyle = computed(() => isRotated.value ? 'rotate(180deg)' : '')

const logout = async () => {
  accountStore.reset()
  window.location.href = '/logout?cgbank'
}

const getBillingPortal = async () => {
  if (user.value?.stripeCustomerId && !user.value?.isBilateral && isPlanButtonAllowed) {
    const response = await useCustomDollarFetch('/api/stripe/portal?source=cgbank')
    if (response) {
      portal.value = response
    }
  }
}

const handlePortalClick = async () => {
  await getBillingPortal()
  if (portal.value) {
    window.open(portal.value, '_blank')
  }
}
</script>

<style lang="scss">
.v-icon {
  font-size: 1.5rem;
  transition: transform 0.3s;
}

.app-layout {
  > .header {
    top: 0;
    background: #f6f6f7;

    > .mask {
      position: fixed;
      padding: -0.7rem -1.5rem;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: rgba(#eeeeee, 0.5);

      @media (max-width: 768px) {
        display: none;
      }
    }

    > .nav {
      display: flex;
      padding: 0.7rem 1.5rem;
      align-items: center;

      > .navarea.-logo {
        height: $body-m;
        width: fit-content;
      }

      > .navarea.-tag {
        padding-left: 0.8rem;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        transition: color 0.3s;

        > .icon {
          font-size: 2rem;
          color: #ed6484;
          transform: v-bind(rotateStyle);
        }

        &:hover {
          color: #ed6484;
        }
      }

      > .tag-content {
        margin-right: 10px;
        color: #ed6484;
      }

      > .navarea.-search-bar {
        flex: 9;
        margin: 0 1rem;
        max-width: 100rem;
        overflow: hidden;
        background-color: white;
      }

      > .navarea.-account {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;

        > .search-icon {
          display: none;
        }

        > .button.-login {
          padding: 5px 20px;
          border-radius: 0.1rem;
          background: #ed6484;
          min-width: 6.875rem;
        }

        > .account-login {
          > .login-else {
            > .button.-logout {
              border-radius: 0.1rem;
              color: #ed6484;
              cursor: pointer;
            }
          }
        }

        > .button.-favorite {
          padding: 5px 20px;
          border-radius: 0.1rem;
          min-width: 7.5rem;
          font-weight: 600;

          &:hover {
            color: #ed6484;
            cursor: pointer;
          }
        }

        > .button.-like {
          display: none;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    > .header > .nav {
      > .navarea.-search-bar {
        display: none;
      }
    }
  }

  @media (max-width: 900px) {
    > .header > .nav {
      > .navarea.-search-bar {
        display: none;
      }

      > .navarea.-tag {
        display: none;
      }

      > .navarea.-account {
        gap: 1rem;
      }

      > .navarea.-account > .button.-favorite {
        display: none;
      }

      > .navarea.-account > .button.-like {
        display: block;

        &:hover {
          cursor: pointer;
          color: #ed6484;
        }
      }

      > .navarea.-account > .search-icon {
        display: block;
        transition: color 0.3s;

        &:hover {
          cursor: pointer;
          color: #ed6484;
        }

        &.active {
          color: #ed6484;
        }
      }
    }
  }
}
</style>
